import * as React from "react"
import BuildInfoJson from '../buildInfo.json'
import {
  Card,
  CardBody,
  CardText,
} from '~/bootstrap/components/card'

const BuildInfo = () => {
  return <Card>
  <CardBody>
<CardText>Build Number: {BuildInfoJson.BuildNumber}</CardText>
<CardText>Build Commit Id: {BuildInfoJson.BuildCommitId}</CardText>
  </CardBody>
</Card>


}



/**
 * BuildInfo page
 */
export default BuildInfo
